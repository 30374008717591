import React, { useRef } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { useTable } from 'react-table/src/hooks/useTable'
import { DefaultTblStyle } from '../common/tables/styles';
import { StatusBadge } from '../Reports/RecordingsTable';
import PALETTE from '../palette';

const SubjectCell = styled.div`
  min-width: 200px;
`;

const reviewsColumns = [
  {
    Header: 'Agent',
    accessor: 'agent',
  },
  {
    Header: 'Reviewer',
    accessor: 'reviewer',
  },
  {
    Header: <div className="nowrap">Created at</div>,
    accessor: 'created_at',
  },
  {
    Header: 'Form',
    accessor: 'form',
    Cell: ({ row, value }) => {
      const { form_deleted: formDeleted } = row.original;

      return (
        <SubjectCell
          className={cx({ 'text-muted': formDeleted })}
          {...(formDeleted ? { title: 'Form is deleted' } : {})}
        >{value}</SubjectCell>
      );
    },
  },
  {
    Header: 'Subject',
    accessor: 'subject',
    Cell: ({ value }) => <SubjectCell>{value}</SubjectCell>,
  },
  {
    Header: <div className="nowrap"># Calls</div>,
    accessor: 'calls',
  },
  {
    Header: 'Score',
    id: 'score',
    Cell: ({ row }) => {
      const { score, delta } = row.original;

      let content = [];
      let scoreColor = PALETTE.NEUTRAL['900'];

      if (score >= 75) {
        scoreColor = PALETTE.GREEN.light;
      } else if (score < 30) {
        scoreColor = PALETTE.RED.light;
      }
      content.push(
        <span key="score" style={{ color: scoreColor }}>{score}</span>
      );

      if (!delta) return content;

      let deltaColor = PALETTE.NEUTRAL['900'];
      let deltaContent;
      if (delta > 0) {
        deltaColor = PALETTE.GREEN.light;
        deltaContent = `↑ ${delta}`;
      } else {
        deltaColor = PALETTE.RED.light;
        deltaContent = `↓ ${Math.abs(delta)}`;
      }
      content.push(
        <small key="delta" style={{ color: deltaColor }}>&nbsp;&nbsp;{deltaContent}</small>
      );

      return content;
    },
  },
  {
    Header: 'Read',
    accessor: 'read_receipt',
    Cell: ({ value }) => {
      return value ? <StatusBadge className={cx('badge badge-secondary badge-fill ml-2')}> <i className="fa fa-check" /></StatusBadge> : <StatusBadge className={cx('badge badge-danger badge-fill ml-2')}> <i className="fa fa-times" /></StatusBadge> ;
    },
  },
  {
    Header: '',
    id: 'view_details',
    Cell: ({ row: { original: review }, allowDelete }) => {
      return (
        <div className="d-flex align-items-baseline justify-content-end nowrap">
          <a href={`/agent_reviews/${review.id}/edit`} target="_blank">Details</a>
          <StatusBadge className={cx('badge badge-secondary badge-fill ml-2', { invisible: !review.complete })}>
            <i className="fa fa-check" />
          </StatusBadge>
          <StatusBadge className={cx('badge badge-fill ml-1', { invisible: !review.has_follow_up && !review.needs_follow_up, 'badge-secondary': review.has_follow_up, 'badge-danger': review.needs_follow_up })}>
            <i className="fa fa-chevron-right" />
          </StatusBadge>
          {allowDelete && <a
            href={`javascript:window.dispatchEvent(new CustomEvent('admin_reviews.delete', {detail: '${review.id}'}))`}
            data-confirm={`Delete review with agent ${review.agent} on ${review.created_at} with form ${review.form}?`}
            className="ml-3"
          >
            <span className="fa fa-trash" />
          </a>}
        </div>
      );
    },
  },
];

const ReviewsTable = ({
  reviews,
  allowDelete,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: reviewsColumns, data: reviews })

  return (
    <DefaultTblStyle noTitle>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell', { allowDelete })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
   );
};

export default ReviewsTable;
