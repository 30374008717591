import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';
import SingleSelect from '../common/form/SingleSelect';
import MultiSelect from '../common/form/MultiSelect';
import SliderSelect from '../common/form/SliderSelect';
import SliderRange from '../common/form/SliderRange';
import Checkbox from '../common/form/Checkbox';
import ClearableBlockInput from '../common/form/ClearableBlockInput';
import { InputLabelBig, BlockInput } from '../common/styles';
import { DATE_PREDEFINED_RANGES } from '../helpers';

const FilterBlock = styled.div`
  margin-top: 24px;
`;

const FilterContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 0;
  overflow-y: auto;
  overflow-x: visible;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
`;

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  height: 100%;
`;

const AdvancedFilters = ({
  filters,
  data,
  onChange,
  onReset,
}) => {
  const [callMapId, setCallMapId] = useState(filters.callMapId);
  const [callType, setCallType] = useState(filters.callType);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [userIds, setUserIds] = useState(filters.userIds);
  const [agentPromptLabels, setAgentPromptLabels] = useState(filters.agentPromptLabels);
  const [customerPromptLabels, setCustomerPromptLabels] = useState(filters.customerPromptLabels);
  const [violationLabels, setViolationLabels] = useState(filters.violationLabels);
  const [outcome, setOutcome] = useState(filters.outcome);
  const [terminationType, setTerminationType] = useState(filters.terminationType);
  const [disposition, setDisposition] = useState(filters.disposition);
  const [dialerDisposition, setDialerDisposition] = useState(filters.dialerDisposition);
  const [benchmarkCeil, setBenchmarkCeil] = useState(filters.benchmarkCeil);
  const [completionFloor, setCompletionFloor] = useState(filters.completionFloor);
  const [campaign, setCampaign] = useState(filters.campaign);
  const [account, setAccount] = useState(filters.account);
  const [metaSearch, setMetaSearch] = useState(filters.metaSearch);
  const [customer, setCustomer] = useState(filters.customer);
  const [contact, setContact] = useState(filters.contact);
  const [dialerId, setDialerId] = useState(filters.dialerId);
  const [phrase, setPhrase] = useState(filters.phrase);
  const [scored, setScored] = useState(filters.scored);
  const [dnis, setDnis] = useState(filters.dnis);
  const [complianceViolationsRange, setComplianceViolationsRange] = useState(filters.complianceViolationsRange);
  const [completionRange, setCompletionRange] = useState(filters.completionRange);
  const [monologueRange, setMonologueRange] = useState(filters.monologueRange);
  const [questionsRange, setQuestionsRange] = useState(filters.questionsRange);
  const [talkRatioRange, setTalkRatioRange] = useState(filters.talkRatioRange);
  const [objectionsRange, setObjectionsRange] = useState(filters.objectionsRange);
  const [durationRange, setDurationRange] = useState(filters.durationRange);
  const [scoreRange, setScoreRange] = useState(filters.scoreRange);
  const [options, setOptions] = useState({
    callMaps: [],
    users: [],
    accounts: [],
    campaigns: [],
    callTypes: [],
    dialerDispositions: [],
    outcomes: [
      { label: 'Any outcome', value: null },
      { label: 'None', value: 'undefined' },
      { label: 'Success', value: 'success' },
      { label: 'Fail', value: 'fail' },
    ],
    terminationTypes: [
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Cut off by next call', value: 'cut_off_by_next_call' },
      { label: 'Manual', value: 'manual' },
      { label: 'Dialer', value: 'dialer' },
      { label: 'Other', value: null }
    ],
  });
  const [dispositionOptions, setDispositionOptions] = useState([]);
  const [agentPromptLblOptions, setAgentPromptLblOptions] = useState([]);
  const [customerPromptLblOptions, setCustomerPromptLblOptions] = useState([]);
  const [violationLabelsOpts, setViolationLabelsOpts] = useState([]);
  const isInitialMount = useRef(true);

  useEffect(() => {
    const groupOptions = data.groups.map(group => {
      return {
        value: `g:${group[0]}`,
        label: <span><i className="fa fa-users"></i> {group[1]}</span>,
      };
    });
    const userOptions = [
      { label: 'Anyone', value: null },
      ...groupOptions,
      ...data.users.map(usr => ({ value: usr[0], label: usr[1] }))
    ];

    const accountOptions = [
      { label: 'Any account', value: null },
      ...data.accounts.map(acc => ({ value: acc[0], label: acc[1] }))
    ];

    const callMapOptions = [
      { label: 'Any call map', value: null },
      ...data.callMaps.map(cm => ({ value: cm[0], label: cm[1] }))
    ];
    const campaignOptions = data.dialerMeta?.campaign ?
      data.dialerMeta?.campaign?.map(c => ({ value: c, label: c }))
      : [];
    const callTypeOptions = data.dialerMeta?.call_type ? [
      { label: 'Any call type', value: null },
      ...data.dialerMeta?.call_type?.map(ct => ({ value: ct, label: ct }))
    ] : [];
    const dialerDispositionOptions = data.dialerMeta?.disposition ?
      data.dialerMeta?.disposition?.map(d => ({ value: d, label: d }))
      : [];

    setOptions({
      ...options,
      callMaps: callMapOptions,
      users: userOptions,
      accounts: accountOptions,
      campaigns: campaignOptions,
      callTypes: callTypeOptions,
      dialerDispositions: dialerDispositionOptions,
    });
    setPromptLblOptions(data.promptLabels);
    setComplianceLabelOpts(data.complianceLabels);
  }, [data]);

  useEffect(() => {
    // ensure disposition/promptLbls are not reset when filters slider is just reopen
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setDisposition([]);
      setAgentPromptLabels([]);
      setCustomerPromptLabels([]);
      setViolationLabels([]);
    }

    if (callMapId) {
      loadDispositions(callMapId);
      loadPromptLabels(callMapId);
      loadComplianceLabels(callMapId);
    }
  }, [callMapId]);

  const setPromptLblOptions = (lblData) => {
    setAgentPromptLblOptions(lblData.agent.map(lbl => ({ value: lbl, label: lbl })));
    setCustomerPromptLblOptions(lblData.customer.map(lbl => ({ value: lbl, label: lbl })));
  };

  const setComplianceLabelOpts = (lblData) => {
    setViolationLabelsOpts(lblData.violations.map(lbl => ({value: lbl, label: lbl.substring(0,50) })));
  }

  const hasCampaign = !!data.dialerMeta?.campaign;
  const hasCallType = !!data.dialerMeta?.call_type;
  const hasDialerDisposition = !!data.dialerMeta?.disposition;

  const handleSave = () => {
    onChange({
      startDate,
      endDate,
      callMapId,
      callType,
      userIds,
      account,
      agentPromptLabels,
      customerPromptLabels,
      violationLabels,
      benchmarkCeil,
      completionFloor,
      outcome,
      terminationType,
      disposition,
      dialerDisposition,
      campaign,
      metaSearch,
      customer,
      contact,
      dialerId,
      phrase,
      scored,
      dnis,
      complianceViolationsRange,
      completionRange,
      monologueRange,
      questionsRange,
      talkRatioRange,
      objectionsRange,
      durationRange,
      scoreRange,
    });
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const resetPromptLabels = (lblData) => {
    setPromptLblOptions(lblData);
    setAgentPromptLabels([]);
    setCustomerPromptLabels([]);
  };

  const resetComplianceLabels = (lblData) => {
    setComplianceLabelOpts(lblData);
    setViolationLabels([]);
  }

  const handleSetCallMapId = (value) => {
    // If the call map id is set to any call map (i.e. null) reset the compliance violations to default
    if (!value) {
      resetPromptLabels(data.promptLabels);
      resetComplianceLabels(data.complianceLabels);
    }
    setCallMapId(value);
  };

  const handleOnReset = () => {
    resetPromptLabels(data.promptLabels);
    resetComplianceLabels(data.complianceLabels);
    onReset();
  }

  const loadDispositions = useCallback((callMapId) => {
    if (!hasDialerDisposition) {
      axios.get('/reports/dispositions', { params: { call_map_id: callMapId } })
            .then(res => {
              setDispositionOptions(
                res.data.dispositions.map(disp => ({ value: disp, label: disp }))
              );
            });
    }
  }, [setDispositionOptions]);

  const loadPromptLabels = (callMapId) => {
    axios.get('/reports/prompt_labels', { params: { call_map_id: callMapId } })
          .then(res => {
            setPromptLblOptions(res.data.prompt_labels);
          });
  };

  const loadComplianceLabels = (callMapId) => {
    axios.get('/reports/compliance_labels', { params: { call_map_id: callMapId } })
    .then(res => {
      setComplianceLabelOpts(res.data.compliance_labels);
    });
  };

  const handleScoreRangeChange = (newRange) => {
    setScoreRange(newRange);
    setScored(true);
  };

  return (
    <PanelWrapper>
      <h4 style={{ padding: '0px 32px 32px' }}>Advanced filters</h4>

      <FilterContent>
        <InputLabelBig>Period</InputLabelBig>
        <DateRangePicker
          initialSettings={{
            ranges: DATE_PREDEFINED_RANGES,
            alwaysShowCalendars: true,
            opens: 'left',
            startDate,
            endDate,
          }}
          onCallback={handleDateRangeChange}
        >
          <BlockInput />
        </DateRangePicker>

        {hasCallType && <FilterBlock>
          <InputLabelBig>Call type</InputLabelBig>
          <SingleSelect
            initialValue={callType}
            options={options.callTypes}
            onChange={setCallType}
          />
        </FilterBlock>}

        <FilterBlock>
          <InputLabelBig>Scored</InputLabelBig>
          <Checkbox
            initialValue={scored}
            onChange={setScored}
          />
        </FilterBlock>

        {hasCampaign && <FilterBlock>
          <InputLabelBig>Campaign</InputLabelBig>
          <MultiSelect
            initialValue={campaign}
            options={options.campaigns}
            onChange={setCampaign}
            placeholder="Any campaign"
          />
        </FilterBlock>}

        <FilterBlock>
          <InputLabelBig>Call data</InputLabelBig>
          <ClearableBlockInput
            placeholder="Search call data"
            reactiveClear
            value={metaSearch}
            onChange={(e) => setMetaSearch(e.target.value)}
            onClear={() => setMetaSearch('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Call map</InputLabelBig>
          <SingleSelect
            initialValue={callMapId}
            options={options.callMaps}
            onChange={handleSetCallMapId}
          />
        </FilterBlock>

        {!hasDialerDisposition && <FilterBlock>
          <InputLabelBig>Disposition</InputLabelBig>
          <MultiSelect
            initialValue={disposition}
            options={dispositionOptions}
            disabled={!callMapId}
            onChange={setDisposition}
            placeholder="Any disposition"
          />
        </FilterBlock>}

        {hasDialerDisposition && <FilterBlock>
          <InputLabelBig>Disposition</InputLabelBig>
          <MultiSelect
            initialValue={dialerDisposition}
            options={options.dialerDispositions}
            onChange={setDialerDisposition}
            placeholder="Any disposition"
          />
        </FilterBlock>}

        <FilterBlock>
          <InputLabelBig>Account</InputLabelBig>
          <SingleSelect
            initialValue={account}
            options={options.accounts}
            onChange={setAccount}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Agent</InputLabelBig>
          <MultiSelect
            initialValue={userIds}
            options={options.users}
            onChange={setUserIds}
            placeholder="Any agent"
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Customer name</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            reactiveClear
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
            onClear={() => setCustomer('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Contact</InputLabelBig>
          <ClearableBlockInput
            placeholder="Contact phone number"
            reactiveClear
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            onClear={() => setContact('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Dialer ID</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            reactiveClear
            value={dialerId}
            onChange={(e) => setDialerId(e.target.value)}
            onClear={() => setDialerId('')}
          />
        </FilterBlock>

        {/* <FilterBlock>
          <InputLabelBig>Word or phrase (agent)</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            value={phrase}
            onChange={(e) => setPhrase(e.target.value)}
            onClear={() => setPhrase('')}
          />
        </FilterBlock> */}

        <FilterBlock>
          <InputLabelBig>Agent prompt</InputLabelBig>
          <MultiSelect
            initialValue={agentPromptLabels}
            options={agentPromptLblOptions}
            onChange={setAgentPromptLabels}
            placeholder="Any prompt"
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Customer prompt</InputLabelBig>
          <MultiSelect
            initialValue={customerPromptLabels}
            options={customerPromptLblOptions}
            onChange={setCustomerPromptLabels}
            placeholder="Any prompt"
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Compliance violations</InputLabelBig>
          <MultiSelect
            initialValue={violationLabels}
            options={violationLabelsOpts}
            onChange={setViolationLabels}
            placeholder="Any violation"
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Outcome</InputLabelBig>
          <SingleSelect
            initialValue={outcome}
            options={options.outcomes}
            onChange={setOutcome}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Termination type</InputLabelBig>
          <MultiSelect
            initialValue={terminationType}
            options={options.terminationTypes}
            onChange={setTerminationType}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>DNIS</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            reactiveClear
            value={dnis}
            onChange={(e) => setDnis(e.target.value)}
            onClear={() => setDnis('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Call length</InputLabelBig>
          <SliderRange
            initialValue={durationRange}
            min={0}
            max={120}
            step={0.25}
            unit="min"
            onChange={setDurationRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Compliance violations</InputLabelBig>
          <SliderRange
            initialValue={complianceViolationsRange}
            min={0}
            max={20}
            step={1}
            onChange={setComplianceViolationsRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Completion</InputLabelBig>
          <SliderRange
            initialValue={completionRange}
            min={0}
            max={100}
            step={1}
            unit="%"
            onChange={setCompletionRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Monologue</InputLabelBig>
          <SliderRange
            initialValue={monologueRange}
            min={0}
            max={600}
            step={10}
            unit="s"
            onChange={setMonologueRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Questions asked</InputLabelBig>
          <SliderRange
            initialValue={questionsRange}
            min={0}
            max={15}
            step={1}
            onChange={setQuestionsRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Talk / Listen ratio</InputLabelBig>
          <SliderRange
            initialValue={talkRatioRange}
            min={0}
            max={100}
            step={1}
            unit="%"
            onChange={setTalkRatioRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Score range</InputLabelBig>
          <SliderRange
            initialValue={scoreRange}
            min={0}
            max={100}
            step={1}
            unit="%"
            onChange={handleScoreRangeChange}
          />
        </FilterBlock>

        {/* <FilterBlock>
          <InputLabelBig>Customer objections</InputLabelBig>
          <SliderRange
            initialValue={objectionsRange}
            min={0}
            max={15}
            step={1}
            onChange={setObjectionsRange}
          />
        </FilterBlock> */}

        <FilterBlock>
          <InputLabelBig>Benchmark less than</InputLabelBig>
          <SliderSelect
            initialValue={benchmarkCeil}
            min={0}
            max={100}
            step={1}
            onChange={setBenchmarkCeil}
          />
        </FilterBlock>

        {/* <FilterBlock>
          <InputLabelBig>Completion more than</InputLabelBig>
          <SliderSelect
            initialValue={completionFloor}
            min={0}
            max={100}
            step={1}
            onChange={setCompletionFloor}
          />
        </FilterBlock> */}
      </FilterContent>

      <div className="row" style={{ paddingLeft: 32, paddingRight: 32, marginTop: 24 }}>
        <div className="col-md-6">
          <button className="btn btn-neutral btn-block btn-lg" onClick={handleOnReset} style={{ fontWeight: 500 }}>Clear Filters</button>
        </div>
        <div className="col-md-6">
          <button className="btn btn-primary btn-block btn-lg" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </PanelWrapper>
  );
};

export default AdvancedFilters;
