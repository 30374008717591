import React, { useState, useMemo, useEffect, useRef } from 'react';
import Switch from 'react-switch';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { InputtyButton, ScopeSwitchLabel } from '../common/styles';
import LeftSlidePanel from '../common/popovers/LeftSlidePanel';
import ClearableBlockInput from '../common/form/ClearableBlockInput';
import AdvancedFilters from './AdvancedFilters';
import SingleSelect from '../common/form/SingleSelect';

const FiltersRow = ({
  showManagerContext,
  filterEvent = 'reports.filters',
  data,
}) => {
  const initialAdvancedFilters = useRef({
    startDate: moment().subtract(1,'d'),
    endDate: moment(),
    callMapId: null,
    callType: null,
    userIds: [],
    account: '', 
    outcome: null,
    terminationType: [],
    disposition: [],
    dialerDisposition: [],
    benchmarkCeil: 100,
    completionFloor: 0,
    managerContext: false,
    campaign: [],
    metaSearch: '',
    customer: '',
    contact: '',
    dialerId: '',
    phrase: null,
    scored: false,
    dnis: '',
    agentPromptLabels: [],
    customerPromptLabels: [],
    violationLabels: [],
    complianceViolationsRange: [0, 20],
    completionRange: [0, 100],
    monologueRange: [0, 600],
    questionsRange: [0, 15],
    talkRatioRange: [0, 100],
    objectionsRange: [0, 15],
    durationRange: [0, 120],
    scoreRange: [0, 100],
  });
  const [filters, setFilters] = useState({
    ...initialAdvancedFilters.current,
    search: '',
    textMatch: 'any',
  });
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const textMatchOptions = [
    {label: 'Any', value: 'any'},
    {label: 'Agent', value: 'agent'},
    {label: 'Customer', value: 'customer'},
  ];

  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel();
    }
  }, []);

  const handleAdvancedChange = (updateBody) => {
    setAdvancedOpen(false);

    setAndNotifyFilters({
      ...filters,
      ...updateBody
    });
  };

  const handleSearch = (evt) => {
    const { target: { value } } = evt;

    setAndNotifyFilters({
      ...filters,
      search: value,
    });
  };

  const handleTextMatch = (value) => {
    // NOTE: Skipping the query when the transcript dropdown selection is changed and no search value is provided.
    if (filters.search) {
      setAndNotifyFilters({
        ...filters,
        textMatch: value,
      });
    } else {
      setFilters({
        ...filters,
        textMatch: value,
      });
    }
  };

  const debouncedSearchHandler = useMemo(
    () => debounce(handleSearch, 2000)
  , [filters]);

  const setAndNotifyFilters = (filters) => {
    const {
      startDate, endDate, search, metaSearch, textMatch, callMapId, managerContext,
      callType, userIds, disposition, dialerDisposition,
      outcome, terminationType, benchmarkCeil, completionFloor, agentPromptLabels, customerPromptLabels, violationLabels,
      campaign, customer, phrase, scored, contact, dialerId, dnis, account,
      complianceViolationsRange, completionRange, monologueRange, questionsRange, talkRatioRange, objectionsRange, durationRange, scoreRange
    } = filters;
    const payload = {
      date_range: `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`,
      call_map: callMapId,
      call_type: callType,
      users: userIds,
      account,
      agent_prompt_labels: agentPromptLabels,
      customer_prompt_labels: customerPromptLabels,
      violation_labels: violationLabels,
      outcome,
      termination_type: terminationType,
      disposition,
      dialer_disposition: dialerDisposition,
      benchmark_ceil: benchmarkCeil,
      completion_floor: completionFloor,
      keyword: search,
      text_match: textMatch,
      meta_keyword: metaSearch,
      manager: managerContext,
      campaign,
      customer,
      contact,
      dialer_id: dialerId,
      phrase,
      scored,
      dnis,
      compliance_violations_range: complianceViolationsRange,
      completion_range: completionRange,
      monologue_range: monologueRange,
      questions_range: questionsRange,
      talk_ratio_range: talkRatioRange,
      objections_range: objectionsRange,
      duration_range: durationRange,
      score_range: scoreRange,
    };

    window.dispatchEvent(
      new CustomEvent(filterEvent, { detail: payload })
    );

    setFilters(filters);
  };

  const handleResetAdvancedFilters = () => {
    setAdvancedOpen(false);

    setAndNotifyFilters({
      ...filters,
      ...initialAdvancedFilters.current
    });
  }

  const handleManagerContextChange = (managerContext) => {
    setAndNotifyFilters({
      ...filters,
      managerContext,
    });
  }

  const handleClearSearch = () => {
    setAndNotifyFilters({
      ...filters,
      search: '',
    });
  };

  return (
    <div>
      {showManagerContext && (
        <div className="d-flex align-items-center mb-4">
          <ScopeSwitchLabel className="mr-2" highlighted={!filters.managerContext}>All calls</ScopeSwitchLabel>
          <Switch
            checked={filters.managerContext}
            onChange={handleManagerContextChange}
            onColor="#888"
            offColor="#888"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={33}
          />
          <ScopeSwitchLabel className="ml-2" highlighted={filters.managerContext}>Team calls</ScopeSwitchLabel>
        </div>
      )}

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <ClearableBlockInput
            placeholder="Search by word or phrase in the transcript"
            style={{ width: 400 }}
            onChange={debouncedSearchHandler}
            onClear={handleClearSearch}
          />
          <div style={{ marginLeft: 30}}>
            <span>Speaker:</span>
          </div>
          <div style={{ marginLeft: 30, width: 150 }}>
            <SingleSelect
              initialValue={filters.textMatch}
              options={textMatchOptions}
              onChange={handleTextMatch}
            />
          </div>
        </div>

        <div style={{ marginLeft: 30 }}>
          <InputtyButton onClick={() => setAdvancedOpen(true)} style={{ width: 200 }}>
            Advanced Filters
          </InputtyButton>
        </div>

        <LeftSlidePanel
          width="450px"
          open={advancedOpen}
          onClose={() => setAdvancedOpen(false)}
        >
          {advancedOpen && (
            <AdvancedFilters
              data={data}
              filters={filters}
              onChange={handleAdvancedChange}
              onReset={handleResetAdvancedFilters}
            />
          )}
        </LeftSlidePanel>
      </div>
    </div>
  );
};

export default FiltersRow;
