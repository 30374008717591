import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table/src/hooks/useTable';
import { DefaultTblStyle } from '../common/tables/styles';
import PALETTE from '../palette';

const getScoreColor = (score) => {
  let scoreColor = PALETTE.NEUTRAL['900'];

  if (score >= 75) {
    scoreColor = PALETTE.GREEN.light;
  } else if (score < 30) {
    scoreColor = PALETTE.RED.light;
  }

  return scoreColor;
};

const getScorecardOrders = scorecards => {
  const sortedScorecardNames = Object.values(scorecards).sort();

  const scorecardOrders = {};
  Object.keys(scorecards).forEach(scorecardId => {
    const scorecardName = scorecards[scorecardId];
    scorecardOrders[scorecardId] = sortedScorecardNames.indexOf(scorecardName);
  });

  return scorecardOrders;
};

const generateTblColumns = (scorecards) => {
  const scorecardOrders = getScorecardOrders(scorecards);
  const columns = [
    {
      Header: 'Agent',
      accessor: 'agent',
    },
    {
      Header: 'Scorer',
      accessor: 'scorer',
    },
    {
      Header: 'Scored at',
      accessor: 'scored_at',
    },
    {
      Header: 'Score',
      id: 'score',
      Cell: ({ row }) => {
        const { score } = row.original;

        return <span style={{ color: getScoreColor(score) }}>{score}</span>;
      },
    },
    {
      Header: 'Scorecard',
      id: 'scorecard',
      Cell: ({ row }) => {
        const { per_scorecards: perScorecards } = row.original;
        const cardIds = Object.keys(perScorecards);

        return (
          <ul className="plain d-flex flex-column">
            {cardIds.map(id => (
              <li key={id} style={{ order: scorecardOrders[id] }}>{scorecards[id]}</li>
            ))}
          </ul>
        );
      }
    },
    {
      Header: 'Scorecard score',
      id: 'scorecard_score',
      Cell: ({ row }) => {
        const { per_scorecards: perScorecards } = row.original;
        const cardIds = Object.keys(perScorecards);

        return (
          <ul className="plain d-flex flex-column">
            {cardIds.map(id => (
              <li key={id} style={{ color: getScoreColor(perScorecards[id]), order: scorecardOrders[id] }}>
                {perScorecards[id]}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      Header: '',
      id: 'view_details',
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-baseline justify-content-end" style={{ whiteSpace: 'nowrap' }}>
            <a href={`/calls/${row.original.id}`} target="_blank">Details</a>
          </div>
        );
      },
    },
  ];

  return columns;
};

const ScoringsTable = ({
  scorings,
  scorecards,
}) => {
  const [columns, setColumns] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: columns, data: scorings })

  useEffect(() => {
    setColumns(
      generateTblColumns(scorecards)
    );
  }, [scorecards]);

  return (
    <DefaultTblStyle noTitle>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
   );
};

export default ScoringsTable;
