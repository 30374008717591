import React, { useEffect, useState, useRef, useCallback } from 'react';
import cx from 'classnames';
import LeftSlideModaless from '../common/popovers/LeftSlideModaless';
import { scoreColor, getAsteriskNotation } from '../helpers';
import ScoringPanel from './scoring/ScoringPanel';

const ScoringButton = ({
  data,
  readOnly: initialReadOnly,
  allowAutoScore
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(initialReadOnly);
  const [results, setResults] = useState(data.results);
  const panelRef = useRef(null);

  useEffect(() => {
    const appElem = document.getElementById('beta');

    if (panelOpen) {
      appElem.classList.add('shrinked');
    } else {
      appElem.classList.remove('shrinked');
    }
  }, [panelOpen]);

  const handlePanelClose = useCallback(() => {
    if (!panelRef.current || !panelRef.current.hasUnsavedScores() || confirm("The score is not saved, continue?")) {
      setPanelOpen(false);
    }
  }, [setPanelOpen]);

  return (
    <div>
      {(data.score_visible || data.isAdmin) && (
        <button
          className={cx("btn btn-primary", { 'd-none': panelOpen })}
          onClick={() => setPanelOpen(true)}>
          {data.scorecards.length > 1 ? 'Average score' : 'Score'}
          {results.total != null && (
            <small
              className="ml-2 badge badge-white"
              style={{ color: scoreColor(results.total) }}>
              {results.total}{getAsteriskNotation(data.scorecards, results.scorecards)}
            </small>
          )}
        </button>
        )}
      <LeftSlideModaless
        width="460px"
        open={panelOpen}
        onClose={handlePanelClose}
      >
        <ScoringPanel
          ref={panelRef}
          callId={data.callId}
          scores={data.scores}
          notes={data.notes}
          scorecards={data.scorecards}
          scorer={data.scorer}
          scoredAt={data.scoredAt}
          lastScorer={data.lastScorer}
          lastScoredAt={data.lastScoredAt}
          results={results}
          readOnly={readOnly}
          allowAutoScore={allowAutoScore}
          onResultsChange={setResults}
          onFinalize={() => setReadOnly(true)}
          isAdmin={data.isAdmin}
        />
      </LeftSlideModaless>
    </div>
  );
};

export default ScoringButton;
